import './charts'
import './inputLargeTextarea'
import './ZohoChat'
import './unlayer'
import './camera'

declare var QRCode: any;

(<any>window).setTitle = (title: string) => {
    // console.log('setting title to: ', title);
    document.title = title;
}

(<any>window).getIpAddress = () => {
    return fetch('https://jsonip.com/')
        .then((response) => response.json())
        .then((data) => {
            return data.ip
        })
}

(<any>window).getUserAgent = () => {
    return navigator.userAgent;
}

(<any>window).createQRCode = (element: string, url: string) => {
    // https://davidshimjs.github.io/qrcodejs/
    const qrcode = new QRCode(element, {
        text: url,
        width: 128 * 2,
        height: 128 * 2,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
    });
    return "";
}

(<any>window).downloadFromByteArray = (options: { byteArray: string, fileName: string, contentType: string }) => {
    console.log('downloadFromByteArray: ', options.fileName);
    // Convert base64 string to numbers array.
    const numArray = atob(options.byteArray).split('').map(c => c.charCodeAt(0));
    // Convert numbers array to Uint8Array object.
    const uint8Array = new Uint8Array(numArray);
    // Wrap it by Blob object.
    const blob = new Blob([uint8Array], {type: options.contentType});
    // Create "object URL" that is linked to the Blob object.
    const url = URL.createObjectURL(blob);
    // Invoke download helper function that implemented in 
    // the earlier section of this article.
    downloadFromUrl({url: url, fileName: options.fileName});
    // At last, release unused resources.
    URL.revokeObjectURL(url);
}

function downloadFromUrl(options: { url: string, fileName?: string }): void {
    const anchorElement = document.createElement('a');
    anchorElement.href = options.url;
    anchorElement.download = options.fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
}

let tooltips: any[];
(<any>window).addTooltips = () => {
    if (tooltips == null) {
        tooltips = [];
    }

    const element = document.querySelector('[data-toggle="tooltip"]');
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // @ts-ignore
        const tooltip = new bootstrap.Tooltip(tooltipTriggerEl);
        tooltips.push(tooltip)
        return tooltip;
    });
}

(<any>window).removeTooltips = () => {
    if (tooltips != null) {
        for (let i = 0; i < tooltips.length; i++) {
            //console.log(tooltips[i]);
            tooltips[i].hide();
        }
    }
}

(<any>window).getCookie = (name: string) => {
    return getCookie(name);
};
(<any>window).getBrowserId = () => {
    return getCookie('ocep-c-id');
};
(<any>window).createBrowserId = () => {
    const name = "ocep-c-id";
    let myCookie = getCookie(name);
    if (myCookie == null) {
        let uid = uuidv4();
        document.cookie = name + "=" + uid;
        console.log("GUID: " + uid)
    } else {
        console.log("Cookie: " + myCookie);
    }
}

function getCookie(name: string) {
    let dc = document.cookie;
    let prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        let end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
        return decodeURI(dc.substring(begin + prefix.length, end));
    }
}

function uuidv4() {
    return window.URL.createObjectURL(new Blob([])).split('/').pop();
}

(<any>window).getSelectedText = (elementId: string) => {
    const textarea = document.getElementById(elementId) as HTMLTextAreaElement;
    return textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
};

(<any>window).clickElementById = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.click();
    }
};

(<any>window).openInTab = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};